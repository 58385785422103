export const DEVELOPER_FIELD = 'customfield_10700'

export const PM_FIELD = 'customfield_10302'

export const VERSION_FIELD = 'customfield_10500'

export const TESTER_FIELD = 'customfield_10501'

export const DEVELOP_GROUP_FIELD = 'customfield_10601'

export enum issueStatus {
  'Backlog' = '10000',
  'ToDo' = '10001',
  'InProgress' = '3',
  'ToTest' = '10500',
  'InTesting' = '10101',
  'ToPublish' = '10501',
  'Done' = '10002'
}

export enum jiraGroups {
  'android' = 'android',
  'IOS' = 'IOS',
  '前端' = '前端',
  '资讯后端' = '资讯后端',
  '数据后端' = '数据后端',
  '测试' = '测试'
}
