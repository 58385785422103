import type { AxiosResponse } from 'axios'
import jira from '@/http/jira'
import type { issueList, jiraUser, project } from './model'

export function getJiraProjects(): Promise<Array<project>> {
  return jira.get('/project')
}

export function getJiraProject(projectIdOrKey: string): Promise<project> {
  return jira.get(`/project/${projectIdOrKey}`)
}

/**
 * @description 搜索 issues
 * @date 2020-12-03
 * @export
 * @param {{
 *   jql: string
 *   startAt: number
 *   maxResults: number
 *   validateQuery: boolean
 *   fields: string
 *   expand: string
 * }} {
 *   jql = '',
 *   startAt = 0,
 *   maxResults = 50,
 *   validateQuery = true,
 *   fields = '',
 *   expand = '',
 * }
 * @returns
 */
export function searchIssues({
  jql = '',
  startAt = 0,
  maxResults = 50,
  validateQuery = true,
  fields = '',
  expand = ''
}: {
  jql?: string
  startAt?: number
  maxResults?: number
  validateQuery?: boolean
  fields?: string
  expand?: string
} = {}): Promise<issueList> {
  return jira.get('/search', {
    params: {
      jql,
      startAt,
      maxResults,
      validateQuery,
      fields,
      expand
    }
  })
}

// 字段提示
export function getJqlFieldSuggestions({ fieldName = '', fieldValue = '' }: { fieldName: string; fieldValue: string }) {
  return jira.get('/jql/autocompletedata/suggestions', {
    params: {
      fieldName,
      fieldValue
    }
  })
}

/**
 * @description 获取自定义字段
 * @date 2020-12-03
 * @export
 * @param {{
 *   startAt?: number
 *   maxResults?: number
 *   search?: string
 *   projectIds?: string
 *   screenIds?: string
 *   types?: string
 * }} [{
 *   startAt = 1,
 *   maxResults = 50,
 *   search = '',
 *   projectIds = '',
 *   screenIds = '',
 *   types = '',
 * }={}]
 * @returns
 */
export function getCustomFields({
  startAt = 1,
  maxResults = 50,
  search = '',
  projectIds = '',
  screenIds = '',
  types = ''
}: {
  startAt?: number
  maxResults?: number
  search?: string
  projectIds?: string
  screenIds?: string
  types?: string
} = {}) {
  return jira.get('/customFields', {
    params: {
      startAt,
      maxResults,
      search,
      projectIds,
      screenIds,
      types
    }
  })
}

export function getCustomFieldOption(id: string) {
  return jira.get(`customFieldOption/${id}`)
}

export function getAppVersionsList(value: string) {
  return getJqlFieldSuggestions({
    fieldName: '版本',
    fieldValue: value
  })
}

export function getPMsList(value: string) {
  return getJqlFieldSuggestions({
    fieldName: 'PM',
    fieldValue: value
  })
}

/**
 * @description 获取组成员
 * @date 2020-12-04
 * @export
 * @param {string} groupname
 * @param {{
 *     includeInactiveUsers?: boolean
 *     startAt?: number
 *     maxResults?: number
 *   }} [{
 *     includeInactiveUsers = false,
 *     startAt = 0,
 *     maxResults = 0,
 *   }={}]
 * @returns
 */
export function getJiraGroupMembers(
  groupname: string,
  {
    includeInactiveUsers = false,
    startAt = 0,
    maxResults = 50
  }: {
    includeInactiveUsers?: boolean
    startAt?: number
    maxResults?: number
  } = {}
): Promise<{
  isLast: boolean
  maxResults: number
  self: string
  startAt: number
  total: number
  values: Array<jiraUser>
}> {
  return jira.get('/group/member', {
    params: {
      groupname,
      includeInactiveUsers,
      startAt,
      maxResults
    }
  })
}
