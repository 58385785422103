
import { computed, ComputedRef, defineComponent, defineProps, inject, ref, watch } from 'vue'
import type { Ref } from 'vue'
import {
  DEVELOP_GROUP_FIELD,
  DEVELOPER_FIELD,
  PM_FIELD,
  TESTER_FIELD,
  issueStatus,
  jiraGroups
} from '@/domains/app-version-manage/constant'
import type { issue, issueStatusName, jiraGroupName } from '@/domains/app-version-manage/model'
import { formatDate } from '@/utils'
import {
  frontEndMembers,
  androidMembers,
  iosdMembers,
  messageBackEndMembers,
  dataBackEndMembers,
  testMembers
} from './jiraUses'
import { findUser } from './GroupProcess.vue'

const developGroupMemberMap = {
  [jiraGroups.android]: androidMembers,
  [jiraGroups.IOS]: iosdMembers,
  [jiraGroups.前端]: frontEndMembers,
  [jiraGroups.资讯后端]: messageBackEndMembers,
  [jiraGroups.数据后端]: dataBackEndMembers,
  [jiraGroups.测试]: testMembers
}

export default defineComponent({
  props: {
    issues: {
      type: Array,
      default() {
        return []
      }
    }
  },
  setup(props) {
    const groupName: Ref<jiraGroupName | undefined> = ref()
    const developStatus: Ref<issueStatusName[]> = ref([])
    const showSubTasks: Ref<boolean> = ref(true)

    const mainTasks = computed(() =>
      ((props as any).issues as Array<issue>)
        .filter((item) => !item.fields.issuetype.subtask)
        .map((mainTask) => {
          const subtasks = mainTask.fields.subtasks.map((_sub: any) => getIssueWithId(_sub.id))
          mainTask.subTasks = subtasks.filter(Boolean)
          return mainTask
        })
    ) as ComputedRef<Array<issue>>

    const tableData = computed(() => {
      const selectedGroup = groupName.value
      const selectedStatus = developStatus.value
      const selectedSubTasks = showSubTasks.value
      const data = mainTasks.value.filter((item) => {
        // 主任务是否满足筛选
        let flag = true
        if (selectedGroup)
          flag =
            flag &&
            item.fields[DEVELOPER_FIELD] &&
            !!findUser(
              item.fields[DEVELOPER_FIELD],
              developGroupMemberMap[
                (selectedGroup as any) as 'android' | 'IOS' | '前端' | '资讯后端' | '数据后端' | '测试'
              ].value
            )

        if (selectedStatus?.length) flag = flag && selectedStatus.includes(item.fields.status.id)
        // 不显示子任务 只筛选 父任务
        if (!selectedSubTasks) {
          item.filterSubTasks = []
          return flag
        }
        // 筛选子任务
        if (selectedSubTasks && item.subTasks && item.subTasks.length) {
          item.filterSubTasks = item.subTasks.filter((_subItem) => {
            let subflag = true
            if (selectedGroup)
              subflag =
                subflag &&
                _subItem.fields[DEVELOPER_FIELD] &&
                !!findUser(
                  _subItem.fields[DEVELOPER_FIELD],
                  developGroupMemberMap[
                    (selectedGroup as any) as 'android' | 'IOS' | '前端' | '资讯后端' | '数据后端' | '测试'
                  ].value
                )
            if (selectedStatus?.length) subflag = subflag && selectedStatus.includes(_subItem.fields.status.id)
            return subflag
          })
        }
        // 主任务满足条件 或者 子任务不为空
        return flag || item.filterSubTasks?.length
      })
      data.sort(
        (a, b) => a?.fields?.subtasks && b?.fields?.subtasks && b.fields.subtasks.length - a.fields.subtasks.length
      )
      return data
    })

    function getIssueWithId(sid: string) {
      return (props as any).issues.find((item: any) => (item as issue).id === sid)
    }

    const runtimeConfig = inject('runtimeConfig')

    return {
      groupName,
      developStatus,
      showSubTasks,
      mainTasks,
      tableData,
      getIssueWithId,
      runtimeConfig,
      formatDate,
      statusColorMap,
      DEVELOP_GROUP_FIELD,
      DEVELOPER_FIELD,
      PM_FIELD,
      TESTER_FIELD,
      issueStatus,
      jiraGroups,
      rowClassName
    }
  }
})

function rowClassName(data: any) {
  if (!data.row.fields.issuetype.subtask) return 'hightlight-primary'
  return ''
}

function statusColorMap(status: string): string {
  return (
    {
      'In Progress': 'warning',
      处理中: 'warning',
      'To Test': 'primary',
      'In Testing': 'primary',
      'To Publish': 'success',
      Done: 'danger',
      完成: 'danger'
    }[status as 'In Progress' | '处理中' | 'To Test' | 'In Testing' | 'To Publish' | 'Done' | '完成'] || 'info'
  )
}
