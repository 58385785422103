import { ref } from 'vue'
import type { Ref } from 'vue'
import {
  getCustomFieldOption,
  getCustomFields,
  getJiraGroupMembers,
  getJiraProject,
  getJiraProjects,
  searchIssues
} from '@/domains/app-version-manage/api'
import type { issue, jiraUser, project } from '@/domains/app-version-manage/model'
import { jiraGroups } from '@/domains/app-version-manage/constant'

const allProjects: Ref<Array<project>> = ref([])

const projectDetail: Ref<project | undefined> = ref()

export const issues: Ref<Array<issue>> = ref([])

export const frontEndMembers: Ref<Array<jiraUser>> = ref([])
export const androidMembers: Ref<Array<jiraUser>> = ref([])
export const iosdMembers: Ref<Array<jiraUser>> = ref([])
export const messageBackEndMembers: Ref<Array<jiraUser>> = ref([])
export const dataBackEndMembers: Ref<Array<jiraUser>> = ref([])
export const testMembers: Ref<Array<jiraUser>> = ref([])

const groupMemberVarMap = {
  [jiraGroups.android]: androidMembers,
  [jiraGroups.IOS]: iosdMembers,
  [jiraGroups.前端]: frontEndMembers,
  [jiraGroups.资讯后端]: messageBackEndMembers,
  [jiraGroups.数据后端]: dataBackEndMembers,
  [jiraGroups.测试]: testMembers
}

/**
 * @description 获取jira项目列表
 * @date 2020-12-03
 * @export
 * @returns {Array<project>}
 */
export function useProjects(): Ref<Array<project>> {
  getJiraProjects()
    .then((res) => {
      allProjects.value = res
    })
    .catch((error) => {
      console.log('error', error)
    })
  return allProjects
}

/**
 * @description 根据 key 筛选 project
 * @date 2020-12-03
 * @export
 * @param {string} key
 * @returns {(project | undefined)}
 */
export function filterProject(key: string): project | undefined {
  return allProjects.value.find((item) => item.key === key)
}

export function useProject() {
  return projectDetail
}

/**
 * @description 刷新项目详情
 * @date 2020-12-03
 * @export
 * @param {string} projectIdOrKey
 */
export function getProjectDetail(projectIdOrKey: string): void {
  getJiraProject(projectIdOrKey)
    .then((res) => {
      projectDetail.value = res
    })
    .catch((error) => {
      console.log('error', error)
    })
}

export async function refreshIssues({ version = '', pm = '' }: { version: string; pm?: string }) {
  let jql = 'project = APP AND issuetype != Bug'
  let startAt = 0
  jql += ` AND 版本 in (${version})`
  if (pm) jql += ` AND PM = ${pm}`
  const _arr: Array<issue> = []
  let total = +Infinity

  while (_arr.length < total) {
    const { issues, total: _total } = await searchIssues({ jql, startAt })
    startAt += issues.length
    total = _total
    _arr.push(...issues)
  }
  issues.value = _arr
}

export function useCustomFields() {
  getCustomFields({
    projectIds: 'APP'
  })
}

export function useCustomFieldOption() {
  getCustomFieldOption('版本')
}

export function initMembers() {
  return Promise.allSettled(
    [
      jiraGroups.android,
      jiraGroups.IOS,
      jiraGroups.前端,
      jiraGroups.资讯后端,
      jiraGroups.数据后端,
      jiraGroups.测试
    ].map((groupName) =>
      userGroupsMembers(groupName, groupMemberVarMap[groupName]).catch((e) => {
        console.log('e', e)
      })
    )
  )
}

export async function userGroupsMembers(groupName: string, targert: Ref<Array<jiraUser>>) {
  let start = 0
  let islast = false
  const _arr: Array<jiraUser> = []
  while (!islast) {
    const { isLast, values } = await getJiraGroupMembers(groupName, {
      startAt: start
    })
    start = values.length
    islast = isLast
    _arr.push(...values)
  }
  targert.value = _arr
}
