import type { AxiosRequestConfig, AxiosResponse } from 'axios'
import config from '@/config'
import AxiosClient from '../base/AxiosClient'

function requestConfig(requestConfig: AxiosRequestConfig): AxiosRequestConfig {
  const conf: AxiosRequestConfig = {
    ...requestConfig,
    headers: {
      Authorization: `Basic ${btoa('shufangyi:123456')}`,
      ...requestConfig.headers
    }
  }
  return conf
}

function handleBusinessError(response: AxiosResponse) {
  return response.data
}
class JiraServiceClient extends AxiosClient {
  constructor(options: AxiosRequestConfig) {
    super(options)
    this.interceptRequest()
    this.interceptResponse()
  }
  interceptRequest() {
    this.client.interceptors.request.use(requestConfig, Promise.reject)
  }
  interceptResponse() {
    this.client.interceptors.response.use(handleBusinessError)
  }
}

export default new JiraServiceClient({
  baseURL: config.apiBase.JIRA_API
})
