<template>
  <el-select
    v-model="selectValue"
    filterable
    remote
    placeholder="请输入版本号"
    :remote-method="remoteMethod"
    :loading="loading"
    clearable
  >
    <el-option v-for="item in options" :key="item.value" :label="item.value" :value="item.value"></el-option>
  </el-select>
</template>

<script>
import { getAppVersionsList } from '@/domains/app-version-manage/api'

export default {
  props: {
    value: String
  },
  data() {
    return {
      // ! vue @3.0.4 的bug
      // * 默认 delfualt slot 里 v-for 是空数组报错。先填一个，待3.0.5发布后 可重置为【】
      options: [{ value: '5.1.0', displayName: '5.1.0' }],
      defaultOptions: [],
      loading: false
    }
  },
  emits: ['update:value'],
  computed: {
    selectValue: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit('update:value', v)
      }
    }
  },
  async mounted() {
    try {
      const { results } = await getAppVersionsList('')
      results.sort((pre, curr) => {
        if (isNaN(parseFloat(pre.value))) return true
        function _fun(version) {
          const arr = version.split('.')
          const length = arr.length
          return arr.reduceRight((acc, curr, idx) => acc + curr * 100 ** (length - idx), 0)
        }
        return _fun(curr.value) - _fun(pre.value)
      })
      this.$nextTick(() => {
        this.selectValue = results[0].value
      })
      this.defaultOptions = results
      this.options = results
    } catch (error) {
      console.log('error', error)
    }
  },
  methods: {
    async remoteMethod(query) {
      if (query !== '') {
        this.loading = true
        try {
          const { results } = await getAppVersionsList(query)
          this.options = results
        } catch (error) {
          this.options = this.defaultOptions
          console.log('error', error)
        } finally {
          this.loading = false
        }
      } else {
        this.options = this.defaultOptions
      }
    }
  }
}
</script>
