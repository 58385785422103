
import { computed, defineComponent, defineProps, watch } from 'vue'
import { ElMessageBox } from 'element-plus'
import type { issue, issueStatusName, jiraGroupName, jiraUser } from '@/domains/app-version-manage/model'
import { DEVELOPER_FIELD, issueStatus, jiraGroups } from '@/domains/app-version-manage/constant'
import {
  frontEndMembers,
  androidMembers,
  iosdMembers,
  messageBackEndMembers,
  dataBackEndMembers,
  testMembers
} from './jiraUses'

type groupIssuesList = issue[]

export default defineComponent({
  props: {
    issues: {
      type: Array,
      default() {
        return []
      }
    }
  },
  setup(props) {
    const tableData = computed(() => {
      const _obj = ((props as any).issues as groupIssuesList).reduce(
        (acc, curr) => {
          const developer = curr.fields[DEVELOPER_FIELD]
          if (!developer) return acc
          if (findUser(developer, frontEndMembers.value)) acc[jiraGroups.前端].push(curr)
          else if (findUser(developer, androidMembers.value)) acc[jiraGroups.android].push(curr)
          else if (findUser(developer, iosdMembers.value)) acc[jiraGroups.IOS].push(curr)
          else if (findUser(developer, messageBackEndMembers.value)) acc[jiraGroups.资讯后端].push(curr)
          else if (findUser(developer, dataBackEndMembers.value)) acc[jiraGroups.数据后端].push(curr)
          else if (findUser(developer, testMembers.value)) acc[jiraGroups.测试].push(curr)

          return acc
        },
        {
          [jiraGroups.android]: [] as groupIssuesList,
          [jiraGroups.IOS]: [] as groupIssuesList,
          [jiraGroups.前端]: [] as groupIssuesList,
          [jiraGroups.资讯后端]: [] as groupIssuesList,
          [jiraGroups.数据后端]: [] as groupIssuesList,
          [jiraGroups.测试]: [] as groupIssuesList
        }
      )
      return Object.keys(_obj).reduce(
        (acc, curr) => {
          const issues = (_obj as any)[curr]
          let process = 0
          if (curr === jiraGroups.测试) {
            process =
              (issues.length &&
                getIssuesInStatusLength(issues, [issueStatus.ToPublish, issueStatus.Done]) / issues.length) * 100
          } else {
            process =
              (issues.length &&
                getIssuesInStatusLength(issues, [
                  issueStatus.ToTest,
                  issueStatus.InTesting,
                  issueStatus.ToPublish,
                  issueStatus.Done
                ]) / issues.length) * 100
          }
          return [
            ...acc,
            {
              issues,
              process,
              label: curr
            }
          ]
        },
        [] as Array<{
          issues: groupIssuesList
          process: number
          label: string
        }>
      )
    })
    return {
      tableData,
      getJobsList,
      getIssuesInStatusLength,
      showProcessDesc,
      issueStatus: issueStatus as any
    }
  }
})

export function findUser(user: jiraUser, list: jiraUser[]): jiraUser | undefined {
  return list.find((item) => item.key === user.key)
}

function getJobsList(issues: groupIssuesList | undefined): Array<issue> {
  if (!issues || !issues.length) return []
  // * 任务数：当前版本/产品的“developer”不为空的需求/任务数（包含 task、sub_task）；
  return issues.filter((item) => (item as issue).fields[DEVELOPER_FIELD])
}

function getIssuesInStatusLength(
  issues: groupIssuesList | undefined,
  status: Array<issueStatusName> | issueStatusName
): number {
  if (!issues || !issues.length) return 0
  const jobs = getJobsList(issues)
  if (!Array.isArray(status)) status = [status]

  return jobs.filter((item) => status.includes(item.fields.status.id)).length
}

function showProcessDesc() {
  ElMessageBox.alert(
    `<p>1. Android、iOS、前端、资讯后端、数据后端 = 该组（待测试+测试中+待上线+已上线）任务数 / 该组任务总数 * 100%；</p>
    <p>2. 测试 = 测试组（待上线+已上线）任务数 / 测试组任务总数 * 100%。</p>`,
    '完成占比数',
    {
      dangerouslyUseHTMLString: true
    }
  )
}
