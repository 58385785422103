<template>
  <el-select
    v-model="selectValue"
    filterable
    remote
    placeholder="请输入产品姓名"
    :remote-method="remoteMethod"
    :loading="loading"
    clearable
  >
    <el-option v-for="item in options" :key="item.value" :label="item.value" :value="item.value">
      <span v-html="item.displayName"></span>
    </el-option>
  </el-select>
</template>

<script>
import { getPMsList } from '@/domains/app-version-manage/api'

export default {
  props: {
    value: String
  },
  data() {
    return {
      // ! vue @3.0.4 的bug
      // * 默认 delfualt slot 里 v-for 是空数组报错。先填一个，待3.0.5发布后 可重置为【】
      options: [{ value: 'daiwei', displayName: '戴维 - daiwei@wallstreetcn.com  (daiwei)' }],
      defaultOptions: [],
      loading: false
    }
  },
  computed: {
    selectValue: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit('update:value', v)
      }
    }
  },
  async mounted() {
    try {
      const { results } = await getPMsList('')
      this.defaultOptions = results
      this.options = results
    } catch (error) {
      console.log('error', error)
    }
  },
  emits: ['update:value'],
  methods: {
    async remoteMethod(query) {
      if (query !== '') {
        this.loading = true
        try {
          const { results } = await getPMsList(query)
          this.options = results
        } catch (error) {
          this.options = this.defaultOptions
          console.log('error', error)
        } finally {
          this.loading = false
        }
      } else {
        this.options = this.defaultOptions
      }
    }
  }
}
</script>
