
import { computed, defineComponent, onMounted, provide, ref, watch, watchEffect } from 'vue'
import type { Ref } from 'vue'
import type { project } from '@/domains/app-version-manage/model'
import AppVersionSelector from '@/views/app-version-manage/index/AppVersionSelector.vue'
import PMSelector from '@/views/app-version-manage/index/PMSelector.vue'
import Overview from '@/views/app-version-manage/index/Overview.vue'
import GroupProcess from '@/views/app-version-manage/index/GroupProcess.vue'
import IssuesList from '@/views/app-version-manage/index/IssuesList.vue'
import {
  useProjects,
  filterProject,
  useProject,
  issues,
  refreshIssues,
  initMembers
} from '@/views/app-version-manage/index/jiraUses'

export default defineComponent({
  components: {
    AppVersionSelector,
    PMSelector,
    Overview,
    GroupProcess,
    IssuesList
  },
  setup() {
    const targetProjectKey: Ref<string> = ref('')
    const allProjects: Ref<Array<project>> = useProjects()
    const projectDetail: Ref<project | undefined> = useProject()

    const appversion: Ref<string | undefined> = ref('')
    const pm: Ref<string | undefined> = ref('')

    const targetProject = computed(() => filterProject(targetProjectKey.value))

    watch(allProjects, (curr, prev) => {
      if (!prev || !prev.length) {
        if (filterProject('APP')) targetProjectKey.value = 'APP'
      }
    })

    watch(
      () => ({ appversion: appversion.value, pm: pm.value }),
      (curr) => {
        if (!curr || !curr.appversion) return
        refreshIssues({
          version: curr.appversion,
          pm: curr.pm
        }).catch((error) => {
          console.log('error', error)
        })
      },
      {
        immediate: true
      }
    )

    initMembers()
    return { appversion, pm, issues }
  }
})
