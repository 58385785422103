<template>
  <el-card class="">
    <template #header>
      <span>需求统计</span>
    </template>
    <div class="flex items-center justify-between">
      <el-card class="flex-grow mx-4">
        <div class="text-center">需求数</div>
        <div class="text-center text-3xl m-4">{{ allTasks.length }}</div> </el-card
      ><el-card class="flex-grow mx-4">
        <div class="text-center">任务数</div>
        <div class="text-center text-3xl m-4 text-warning">{{ jobs.length }}</div> </el-card
      ><el-card class="flex-grow mx-4">
        <div class="text-center">已开发</div>
        <div class="text-center text-3xl m-4 text-danger">{{ developeds.length }}</div> </el-card
      ><el-card class="flex-grow mx-4">
        <div class="text-center">已测完</div>
        <div class="text-center text-3xl m-4 text-success">{{ testeds.length }}</div>
      </el-card>
    </div>
  </el-card>
</template>

<script lang="ts">
import { computed, ComputedRef, defineComponent, defineProps } from 'vue'
import type { issue } from '@/domains/app-version-manage/model'
import { DEVELOPER_FIELD, issueStatus } from '@/domains/app-version-manage/constant'

export default defineComponent({
  props: {
    issues: {
      type: Array,
      default() {
        return []
      }
    }
  },
  setup(props) {
    // * 需求数：当前版本/产品的“Task”总数（不包含 sub_task）；
    const allTasks = computed(() =>
      (props as any).issues.filter((item: any) => !(item as issue).fields.issuetype.subtask)
    ) as ComputedRef<Array<issue>>

    // * 任务数：当前版本/产品的“developer”不为空的需求/任务数（包含 task、sub_task）；
    const jobs = computed(() =>
      (props as any).issues.filter((item: any) => (item as issue).fields[DEVELOPER_FIELD])
    ) as ComputedRef<Array<issue>>

    // * 已开发数：上述“任务数”中，状态为“待测试、测试中、待上线、已上线”任一状态的任务数
    const developeds = computed(() =>
      jobs.value.filter((item) =>
        [issueStatus.ToTest, issueStatus.InTesting, issueStatus.ToPublish, issueStatus.Done].includes(
          item.fields.status.id
        )
      )
    )
    // * 已测完数：上述“任务数”中，状态为“待上线、已上线”任一状态的任务数。
    const testeds = computed(() =>
      jobs.value.filter((item) => [issueStatus.ToPublish, issueStatus.Done].includes(item.fields.status.id))
    )

    return {
      allTasks,
      jobs,
      developeds,
      testeds
    }
  }
})
</script>
