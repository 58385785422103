<template>
  <el-card>
    <template #header>
      <span>需求列表</span>
    </template>
    <div>
      <el-form inline>
        <el-form-item>
          <el-select v-model="groupName" clearable placeholder="筛选开发组">
            <el-option v-for="group in jiraGroups" :key="group" :label="group" :value="group"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select v-model="developStatus" multiple clearable placeholder="筛选任务状态">
            <el-option v-for="(key, status) in issueStatus" :key="key" :label="status" :value="key"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="显示子任务">
          <el-switch v-model="showSubTasks"></el-switch>
        </el-form-item>
      </el-form>
      <el-table
        class="w-full"
        row-key="id"
        size="medium"
        :data="tableData"
        :row-class-name="rowClassName"
        :tree-props="{ children: 'filterSubTasks' }"
        default-expand-all
      >
        >
        <el-table-column label="需求ID" align="center" width="150">
          <template #default="{ row: { key } }">{{ key }}</template>
        </el-table-column>
        <el-table-column label="需求概要">
          <template #default="{ row: { fields, key, subTasks } }">
            <a :href="`${runtimeConfig.siteDomain.JIRA_DOMAIN}/browse/${key}`" target="_blank">{{ fields?.summary }}</a>
            <template v-if="fields?.subtasks?.length">
              <span>({{ fields.subtasks.length }})</span>
              <el-tag
                v-if="subTasks && fields.subtasks.length !== subTasks.length"
                type="danger"
                size="mini"
                style="display: inline-block"
                >需求数不一致</el-tag
              >
            </template>
          </template>
        </el-table-column>
        <el-table-column label="产品经理" align="center" width="150">
          <template #default="{ row: { fields } }">{{ fields?.[PM_FIELD]?.displayName }}</template>
        </el-table-column>
        <el-table-column label="开发人员" align="center" width="150">
          <template #default="{ row: { fields } }">
            <el-tag v-if="fields?.[DEVELOPER_FIELD]" class="w-20 m-1" type="info">{{
              fields[DEVELOPER_FIELD]?.displayName
            }}</el-tag>
            <div v-else>--</div>
          </template>
        </el-table-column>
        <el-table-column label="测试人员" align="center" width="150">
          <template #default="{ row: { fields } }">
            <div v-if="fields?.[TESTER_FIELD]?.length">
              <el-tag class="w-20 m-1" v-for="deve in fields[TESTER_FIELD]" :key="deve.displayName" type="info">{{
                deve.displayName
              }}</el-tag>
            </div>
            <div v-else>--</div>
          </template>
        </el-table-column>
        <el-table-column label="状态" align="center" width="150">
          <template #default="{ row: { fields } }">
            <el-tag class="w-20 m-1" v-if="fields?.status?.name" :type="statusColorMap(fields.status.name)">{{
              fields.status.name
            }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="创建时间" align="center" width="150">
          <template #default="{ row: { fields } }">{{ formatDate(fields?.created) }}</template>
        </el-table-column>
      </el-table>
    </div>
  </el-card>
</template>

<script lang="ts">
import { computed, ComputedRef, defineComponent, defineProps, inject, ref, watch } from 'vue'
import type { Ref } from 'vue'
import {
  DEVELOP_GROUP_FIELD,
  DEVELOPER_FIELD,
  PM_FIELD,
  TESTER_FIELD,
  issueStatus,
  jiraGroups
} from '@/domains/app-version-manage/constant'
import type { issue, issueStatusName, jiraGroupName } from '@/domains/app-version-manage/model'
import { formatDate } from '@/utils'
import {
  frontEndMembers,
  androidMembers,
  iosdMembers,
  messageBackEndMembers,
  dataBackEndMembers,
  testMembers
} from './jiraUses'
import { findUser } from './GroupProcess.vue'

const developGroupMemberMap = {
  [jiraGroups.android]: androidMembers,
  [jiraGroups.IOS]: iosdMembers,
  [jiraGroups.前端]: frontEndMembers,
  [jiraGroups.资讯后端]: messageBackEndMembers,
  [jiraGroups.数据后端]: dataBackEndMembers,
  [jiraGroups.测试]: testMembers
}

export default defineComponent({
  props: {
    issues: {
      type: Array,
      default() {
        return []
      }
    }
  },
  setup(props) {
    const groupName: Ref<jiraGroupName | undefined> = ref()
    const developStatus: Ref<issueStatusName[]> = ref([])
    const showSubTasks: Ref<boolean> = ref(true)

    const mainTasks = computed(() =>
      ((props as any).issues as Array<issue>)
        .filter((item) => !item.fields.issuetype.subtask)
        .map((mainTask) => {
          const subtasks = mainTask.fields.subtasks.map((_sub: any) => getIssueWithId(_sub.id))
          mainTask.subTasks = subtasks.filter(Boolean)
          return mainTask
        })
    ) as ComputedRef<Array<issue>>

    const tableData = computed(() => {
      const selectedGroup = groupName.value
      const selectedStatus = developStatus.value
      const selectedSubTasks = showSubTasks.value
      const data = mainTasks.value.filter((item) => {
        // 主任务是否满足筛选
        let flag = true
        if (selectedGroup)
          flag =
            flag &&
            item.fields[DEVELOPER_FIELD] &&
            !!findUser(
              item.fields[DEVELOPER_FIELD],
              developGroupMemberMap[
                (selectedGroup as any) as 'android' | 'IOS' | '前端' | '资讯后端' | '数据后端' | '测试'
              ].value
            )

        if (selectedStatus?.length) flag = flag && selectedStatus.includes(item.fields.status.id)
        // 不显示子任务 只筛选 父任务
        if (!selectedSubTasks) {
          item.filterSubTasks = []
          return flag
        }
        // 筛选子任务
        if (selectedSubTasks && item.subTasks && item.subTasks.length) {
          item.filterSubTasks = item.subTasks.filter((_subItem) => {
            let subflag = true
            if (selectedGroup)
              subflag =
                subflag &&
                _subItem.fields[DEVELOPER_FIELD] &&
                !!findUser(
                  _subItem.fields[DEVELOPER_FIELD],
                  developGroupMemberMap[
                    (selectedGroup as any) as 'android' | 'IOS' | '前端' | '资讯后端' | '数据后端' | '测试'
                  ].value
                )
            if (selectedStatus?.length) subflag = subflag && selectedStatus.includes(_subItem.fields.status.id)
            return subflag
          })
        }
        // 主任务满足条件 或者 子任务不为空
        return flag || item.filterSubTasks?.length
      })
      data.sort(
        (a, b) => a?.fields?.subtasks && b?.fields?.subtasks && b.fields.subtasks.length - a.fields.subtasks.length
      )
      return data
    })

    function getIssueWithId(sid: string) {
      return (props as any).issues.find((item: any) => (item as issue).id === sid)
    }

    const runtimeConfig = inject('runtimeConfig')

    return {
      groupName,
      developStatus,
      showSubTasks,
      mainTasks,
      tableData,
      getIssueWithId,
      runtimeConfig,
      formatDate,
      statusColorMap,
      DEVELOP_GROUP_FIELD,
      DEVELOPER_FIELD,
      PM_FIELD,
      TESTER_FIELD,
      issueStatus,
      jiraGroups,
      rowClassName
    }
  }
})

function rowClassName(data: any) {
  if (!data.row.fields.issuetype.subtask) return 'hightlight-primary'
  return ''
}

function statusColorMap(status: string): string {
  return (
    {
      'In Progress': 'warning',
      处理中: 'warning',
      'To Test': 'primary',
      'In Testing': 'primary',
      'To Publish': 'success',
      Done: 'danger',
      完成: 'danger'
    }[status as 'In Progress' | '处理中' | 'To Test' | 'In Testing' | 'To Publish' | 'Done' | '完成'] || 'info'
  )
}
</script>
